import React from 'react'
import { Heading, Text } from 'rebass'
import { graphql, Link } from 'gatsby'
import Img from 'gatsby-image'

import Layout from '../components/layout'
import SEO from '../components/seo'
import Content from '../components/content'

const NotFoundPage = ({ data }) => (
  <Layout>
    <SEO title="404: Not found" />
    <Content css={{ textAlign: 'center' }} my={4}>
      <Img
        fluid={data.file.childImageSharp.fluid}
        css={{ maxWidth: '300px', margin: '46px auto' }}
      />
      <Heading>Pagina non trovata!</Heading>
      <Text mb={4}>
        La pagina potrebbe aver preso fuoco:{' '}
        <Link to="/">mettiti in sicurezza...</Link>
      </Text>
    </Content>
  </Layout>
)

export const ImageQuery = graphql`
  {
    file(relativePath: { eq: "not_found.png" }) {
      publicURL
      childImageSharp {
        fluid(maxWidth: 300) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`

export default NotFoundPage
